<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <h2 class="font-semibold">Manage Homepage Service</h2>
        <button
          @click="navigateToAddHomepageService"
          class="btn btn-sm bg-green-400 border-none"
        >
          Add New
        </button>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <table
          class="
            border-collapse
            table-auto
            w-full
            whitespace-no-wrap
            bg-white
            table-striped
            relative
          "
        >
          <thead>
            <tr class="text-left">
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Title
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Service 1
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Service 2
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Service 3
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Service 4
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Last Updated At
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs text-center
                "
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in homepageServiceList" :key="item">
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{ item.homepageServiceTitle }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    convertServiceIDtoServiceName(item.homepageService1)
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    convertServiceIDtoServiceName(item.homepageService2)
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    convertServiceIDtoServiceName(item.homepageService3)
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    convertServiceIDtoServiceName(item.homepageService4)
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    moment(item.updated_at).format(
                      "ddd MMM DD, YYYY [at] hh:mm a"
                    )
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <button
                  @click="navigateToEditHomepageService(item.id)"
                  class="px-2"
                >
                  <PencilAltIcon class="h-8 w-8 text-yellow-500" />
                </button>
                <button @click="deleteHomepageService(item.id)" class="px-2">
                  <TrashIcon class="h-8 w-8 text-red-500" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import HomepageServiceCard from "../../components/HomepageService/HomepageServiceCard.vue";
import { TrashIcon, PencilAltIcon } from "@heroicons/vue/outline";
import moment from "moment";

export default {
  name: "ManageHomepageService",
  components: {
    SideNavBar,
    HomepageServiceCard,
    TrashIcon,
    PencilAltIcon,
  },
  data() {
    return {
      isLoading: true,
      serviceList: [],
      homepageServiceList: [],
    };
  },
  mounted() {
    this.moment = moment;
    this.getServiceDetail();
  },
  methods: {
    async getServiceDetail() {
      try {
        let response = await this.axios({
          url: "/manageHomeService/getServiceDetail",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        this.serviceList = response.data["services"];
        this.homepageServiceList = response.data["homePageService"];
        this.isLoading = false;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push({
            name: "Login",
            query: {
              error:
                "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
            },
          });
          this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
        }
      }
    },
    async saveHomePageService() {
      let formData = new FormData();
      for (var i = 0; i < this.homePageServiceForm.length; i++) {
        formData.append(
          "homepageServiceTitle[]",
          this.homePageServiceForm[i].homepageServiceTitle == null
            ? ""
            : this.homePageServiceForm[i].homepageServiceTitle
        );
        formData.append(
          "homepageService1[]",
          this.homePageServiceForm[i].homepageService1
        );
        formData.append(
          "homepageService2[]",
          this.homePageServiceForm[i].homepageService2
        );
        formData.append(
          "homepageService3[]",
          this.homePageServiceForm[i].homepageService3
        );
        formData.append(
          "homepageService4[]",
          this.homePageServiceForm[i].homepageService4
        );
      }

      await this.axios.post(
        "/manageHomeService/updateHomePageService",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.getServiceDetail();
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: "success",
        title: response.data["message"],
      });
    },
    async deleteHomepageService(homepageServiceID) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this homepage service?",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Yes",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (!result.isDismissed) {
            let response = await this.axios({
              url: "/manageHomeService/deleteHomepageService",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                homepageServiceID: homepageServiceID,
              },
            });

            this.showToastDialog(response);
            this.getServiceDetail();
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    showToastDialog(response) {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: response.status == 200 ? "success" : "error",
        title: response.data["message"],
      });
    },
    convertServiceIDtoServiceName(serviceID) {
      for (var i = 0; i < this.serviceList.length; i++) {
        if (this.serviceList[i].id == serviceID) {
          return this.serviceList[i].serviceName;
        }
      }
    },
    navigateToAddHomepageService() {
      this.$router.push({ name: "AddHomepageService" });
    },
    navigateToEditHomepageService(homepageServiceID) {
      this.$router.push({
        name: "EditHomepageService",
        params: { homepageServiceID: homepageServiceID },
      });
    },
  },
};
</script>

<style scoped>
.homepage-service-card {
  margin: 16px 0;
}
</style>
